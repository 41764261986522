import React, { useState } from 'react'
import { graphql } from 'gatsby'

import Layout from '../layout'
import Posts from '../components/Posts'
import * as styles from '../pages/blog.module.css'
import CategoriesBar from '../components/CategoriesBar'
import PropTypes from 'prop-types'

const CategoryTemplate = ({ data, pageContext }) => {
  const category = pageContext.category
  const categoryCount = data.allMarkdownRemark.edges.length
  const postMessage = categoryCount === 1 ? 'post' : 'posts'

  const allPosts = data.allMarkdownRemark.edges
  const emptyQuery = ''

  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
  })

  const handleInputChange = event => {
    const query = event.target.value
    const posts = allPosts || []

    const filteredData = posts.filter(post => {
      const { title, tags } = post.node.frontmatter
      return (
        title.toLowerCase().includes(query.toLowerCase()) ||
        (tags &&
          tags
            .join('')
            .toLowerCase()
            .includes(query.toLowerCase()))
      )
    })
    setState({ query, filteredData })
  }
  const { filteredData, query } = state
  const hasSearchResults = filteredData && query !== emptyQuery
  const posts = hasSearchResults ? filteredData : allPosts

  return (
    <Layout>
      <div className={styles.blogPageContainer}>
        <div className={styles.headerBlogsContainer}>
          <header className={styles.header}>
            <h1 className={styles.title}>
              <span className={styles.count}>{categoryCount}</span>{' '}
              {postMessage} on{' '}
              <span className={styles.category}>{category}</span>
            </h1>
            <CategoriesBar />
            <form>
              <input
                className={styles.input}
                onChange={handleInputChange}
                placeholder="Type here to search..."
              />
            </form>
          </header>
          {posts.map(post => {
            return <Posts frontmatter={post.node.frontmatter} key={post.path} />
          })}
        </div>
      </div>
    </Layout>
  )
}

CategoryTemplate.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.string,
      edges: PropTypes.string,
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        path: PropTypes.string,
        tags: PropTypes.string,
        category: PropTypes.string,
        date: PropTypes.string,
      }),
    }),
  }),
  pageContext: {
    category: PropTypes.string,
  },
}

export default CategoryTemplate

export const pageQuery = graphql`
  query CategoryPage($category: String) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { category: { in: [$category] } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            path
            tags
            category
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
